import React, { Fragment, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import Img from "gatsby-image"

import Layout from "layout"
import Container from "layout/Container"
import Button from "elements/Button"

import { isObjectEmpty, isBrowser } from "services/general"
import { getSignedInUser } from "components/Auth/services/user"

import styles from "./utils/bookingComplete.module.scss"
import { useBookingCompleteImages } from "./hooks/useBookingCompleteImages"
import { ConsultationContext } from "../ConsultationContext/ConsultationContext"

const Completed = (props) => {
  const { pageContext, location } = props
  const { module, content, nextPath } = pageContext
  const data = useBookingCompleteImages()
  const { consultationDispatch } = useContext(ConsultationContext)
  const { accessRule } = getSignedInUser()
  const homeUrl = accessRule?.homeUrl

  let start_time, channel, completeMsg, dateString, parsedTime

  const handleRedirect = () => {
    if (homeUrl) {
      if (isBrowser()) window.location.href = homeUrl
    } else navigate(nextPath)
  }

  switch (!isObjectEmpty(location?.state?.[0])) {
    // case Physical
    case true:
      ;({ start_time, channel, completeMsg } = location?.state[0])

      parsedTime = new Date(start_time)

      dateString = parsedTime.toLocaleDateString("PST", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
      break
    // case Mind
    default:
    //TODO: add values
  }

  useEffect(() => {
    const restartDetails = async () => {
      await consultationDispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
    }
    restartDetails()
  }, [consultationDispatch])

  return (
    <Layout seoTitle={module.seoTitle} isPrivate pageContext={pageContext} isCompletePages>
      <Container isCentered customClassName="mb-2-mobile">
        <center>
          <Container isCentered mobile={10} tablet={6} desktop={8}>
            {content.icon && (
              <Img
                fluid={data[content.icon].childImageSharp.fluid}
                alt={`${module.name} Complete`}
                className={styles["image"]}
              />
            )}
          </Container>
          <h1 className="title has-text-primary">
            {module.title}
          </h1>

          <div className="column">
            <Fragment>
              <p className="mb-2 header-size-text">
                You have successfully booked a teleconsult for{" "}
                <b>{dateString}</b> via <b>{channel}</b>. {completeMsg}
              </p>
            </Fragment>
          </div>
          <center>
            <Button color="primary" onClick={handleRedirect} size="medium">
              {pageContext?.cta ? pageContext.cta : "Finish"}
            </Button>
          </center>
        </center>
      </Container>
    </Layout>
  )
}

export default Completed
